import React from "react";
import { 
  BrowserRouter as Router, 
  Route, 
  Routes,
} from 'react-router-dom';
import history from './browserHistory';

import { useMessageDispatch } from 'itaf';

import LoginPage from "./pages/Login";

import AppPage from "./pages/App";
import BusinessPage from "./pages/Cadastros";
import ClientesPage from "./pages/Clientes";
import ControllershipPage from "./pages/Controllership";
import DashboardPage from "./pages/Dashboard";
import RegisterPage from "./pages/Register";
import ReportPage from "./pages/Report";
import FiscalPage from "./pages/Fiscal";

import ProtectedRoute from './components/ProtectedRoute';

export const AppRoutes = {
  NOT_FOUND: "*",
  ROOT: "/",
  LOGIN: "/login",
  APP: "/app",
  BUSINESS: "cadastros",
  CONTROLLERSHIP: "controladoria",
  CUSTOMER: "clientes",
  DASHBOARD: "dashboard",
  FISCAL: "fiscal",
  REGISTER: "usuarios",
  REPORT: "relatorios",
  SPED: "sped",
  
  PROFILE: "/app/perfil",
}

function getDashboard() {
  return <ProtectedRoute><DashboardPage /></ProtectedRoute>;
}

function getRegister() {
  return <ProtectedRoute><RegisterPage /></ProtectedRoute>;
}

const LocalRoutes = () => {
  const setMessage = useMessageDispatch();

  const onMessage = (msg) => { 
    setMessage(msg.message)
  }
  
  return (
    <Router history={history}>
      <Routes>
        <Route path={AppRoutes.ROOT} element={<LoginPage />} />
        <Route path={AppRoutes.LOGIN} element={<LoginPage />} />
        <Route path={AppRoutes.NOT_FOUND} element={<LoginPage />} />

        <Route path={AppRoutes.APP} element={<AppPage />} >
          <Route index element={getDashboard()} />
          <Route path={AppRoutes.DASHBOARD} element={getDashboard()} />
          <Route path={AppRoutes.REGISTER} element={getRegister()} />

          <Route path={AppRoutes.BUSINESS} 
            element={<BusinessPage url={process.env.REACT_APP_CADASTRO_URL || ""} onMessage={onMessage}/>}
          />
          <Route path={AppRoutes.CONTROLLERSHIP} 
            element={<ControllershipPage url={process.env.REACT_APP_CONTROLLERSHIP_URL || ""} onMessage={onMessage}/>}
          />
          <Route path={AppRoutes.CUSTOMER} 
            element={<ClientesPage url={process.env.REACT_APP_CUSTOMER_URL || ""} onMessage={onMessage}/> }
          />
          <Route path={AppRoutes.FISCAL} 
            element={<FiscalPage url={process.env.REACT_APP_FISCAL_URL || ""} onMessage={onMessage}/>}
          />
          <Route path={AppRoutes.REPORT} 
            element={<ReportPage url={process.env.REACT_APP_REPORT_URL || ""} onMessage={onMessage}/>}
          />
          <Route path={AppRoutes.SPED} 
            element={<FiscalPage url={process.env.REACT_APP_SPED_URL || ""} onMessage={onMessage}/>}
          />
        </Route>

        {/* <ProtectedRoute path={AppRoutes.APP} element={DashboardPage} />
        <ProtectedRoute path={AppRoutes.REGISTER} element={RegisterPage} />
        <ProtectedRoute path={AppRoutes.DASHBOARD} element={DashboardPage} />
        <ProtectedRoute
          path={AppRoutes.ACCOUNTS}
          element={AccountsPageRouteComponent}
        />
        <ProtectedRoute
          path={AppRoutes.SPED}
          element={FiscalPageRouteComponent}
        />
        <ProtectedRoute
          path={AppRoutes.CUSTOMER}
    //      element={FiscalPageRouteComponent}
          element={CustomerPageRouteComponent}
        />
        <ProtectedRoute path={AppRoutes.PROFILE} element={ProfilePage} /> */}
      </Routes>
    </Router>
  );
};

export default LocalRoutes;
