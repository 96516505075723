import React, { useState, useEffect, Fragment } from "react";

import {
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Drawer,
  Paper,
  Typography,
} from "@mui/material";

import IconImageEye from "@mui/icons-material/RemoveRedEye";
import CancelIcon from '@mui/icons-material/Cancel';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';

import MaterialReactTable from 'material-react-table';

import styled from '@emotion/styled'
const DrawerPaper = styled(Paper)(() => ({
  width: '60%',
  minHeight: '100vh',
}));
const DrawerPaperSm = styled(Paper)(() => ({
  width: '30%',
}));
const DrawerPaperLg = styled(Paper)(() => ({
  width: '90%',
}));

export default function OffCanvas(props) {
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState({ showPanel: false });
  const { showPanel } = state;

  useEffect(() => {
    setState({ showPanel: props.showPanel });
  }, [props.showPanel]);

  useEffect(() => {
    setLoading(props.isLoading);
  }, [props.isLoading]);

  const handleOpenClick = () => {
    setState({ showPanel: true });
  };

  const handleCloseClick = () => {
    setLoading(true);
    setState({ showPanel: false });
    if (props.onClosedPanel) {
      props.onClosedPanel();
    }
    setLoading(false);
  };

  function progress() {
    if (props.progress) {
     return (
      <Backdrop
        sx={{ color: '#fff', position: 'absolute', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showPanel}
      >
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) =>
                theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
            }}
            size={80}
            thickness={6}
            value={100}
          />
          <CircularProgress variant="determinate" value={props.progress} 
            disableShrink
            sx={{
              position: 'absolute',
              left: 0,
            }}
            size={80}
            thickness={6}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
            >{`${Math.round(props.progress)}%`}</Typography>
          </Box>
        </Box>
      </Backdrop>
    )}

    return (
      <Backdrop
        sx={{ color: '#fff', position: 'absolute', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showPanel}
      >
        <CircularProgress color="inherit" size={80} thickness={6} />
      </Backdrop>)
  }

  return (
    <Fragment>
      <CssBaseline />
      {props.showIcon && (
        <Button onClick={handleOpenClick}>
          <IconImageEye />
        </Button>
      )}
      <Drawer
        ModalProps={{ sx:{ zIndex: (theme) => theme.zIndex.modal } }}
        PaperProps={{ 
          style: { 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
          component: (props.widthSm) ? DrawerPaperSm : (props.widthLg) ? DrawerPaperLg : DrawerPaper
        }}
        anchor="right" open={showPanel} onClose={handleCloseClick}
      >
        <MaterialReactTable
          columns={[
            {
              header: '',
              accessorKey: "title",
              enableColumnActions: false,
              muiTableHeadCellProps: {
                sx: theme => ({
                  display: 'none',
                })
              },
            },
          ]}
          data={[]}
          enableStickyHeader
          enableStickyFooter
          enableToolbarInternalActions={false}
          enableTopToolbar={true}
          enableBottomToolbar={true}
          muiTablePaperProps={{ sx: { boxShadow: 'none', height: '100%' } }}
          muiTableProps={{
            sx: { minHeight: `calc(100vh - 96px - 68px)`, },
          }}
          muiTableContainerProps={{
            sx: { maxHeight: `calc(100vh - 96px - 40px)`, }
          }}
          muiTableHeadProps={{ sx: { display: 'none', } }}
          renderTopToolbarCustomActions={() => (
            <Container component="span" maxWidth={false}
              sx={(theme) => ({ margin: `${theme.spacing(0)} ${theme.spacing(0)}`, 
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              paddingLeft: `${theme.spacing(2)} !important`,
              paddingRight: `${theme.spacing(2)} !important`,
            })}>
              <Paper sx={(theme) => ({ width: '100%', display: 'grid', gap: '10px',
                gridTemplateColumns: '30px auto',
                padding: `${theme.spacing(1)} ${theme.spacing(2)}` })}
              >
                <Typography color="textPrimary" sx={{ display: 'flex' }}>
                  {props.header.add && (<AddBoxIcon />)}
                  {props.header.edit && (<EditIcon />)}
                  {props.header.icon}
                </Typography>
                <Typography color="textPrimary" noWrap={true} 
                  sx={{ display: 'block', maxWidth: '95%', fontWeight: 600,
                    fontSize: '1.15rem' 
                }}>
                  {props.header.title}
                </Typography>
              </Paper>
            </Container>
          )}
          renderBottomToolbar={() => (
            <Container component="span" maxWidth={false}
              sx={(theme) => ({ margin: `${theme.spacing(0)} ${theme.spacing(0)}`, 
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(1) 
            })}>
              {props.buttons}
              <Button
                variant="contained"
                startIcon={<CancelIcon />}
                disabled={isLoading}
                onClick={handleCloseClick}
                sx={(theme) => ({ marginLeft: props.buttons ? theme.spacing(2) : 0 })}
              >
                {props.closeLabel ? props.closeLabel : "Cancelar"}
              </Button>
            </Container>
          )}
          muiTableBodyProps={{ children: (
            <Container component="span" maxWidth={false}
              sx={(theme) => ({ margin: `${theme.spacing(0)} ${theme.spacing(0)}`
            })}>
              {props.children}
            </Container>
          ),
            sx: { height: '100%' } 
          }}
        />
        {isLoading &&
          progress()
        }
      </Drawer>
    </Fragment>
  );
}
