import React from "react";

import { Checkbox, FormControlLabel } from '@mui/material';

import Brightness1Icon from '@mui/icons-material/Brightness1';

export default function LegendButton({ item }) {
  const separator = '#';

  function getName(id) {
    return id.substring(id.lastIndexOf(separator) + 1, id.length);
  }

  return (
    <FormControlLabel 
      label={getName(item.name)}
      control={
        <Checkbox
          label={getName(item.name)}
          icon={<Brightness1Icon />}
          checkedIcon={<Brightness1Icon />}
          sx={{
            color: item.color,
            cursor: 'default',
            '&.Mui-checked': {
              color: item.color,
            },
          }}
        />
      }
      sx={{
        cursor: 'default',
        fontSize: '1rem'
      }}
    />
  );
};