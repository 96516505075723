import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import MaterialTable from '@material-table/core';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
  DefaultLocalization,
  DefaultOptions,
  OffCanvas,
  SaveButton,
  useMessageDispatch,
} from 'itaf';

import NotificationService from '../../services/NotificationService';
import UserService from '../../services/UserService';

export default function RegisterCanvas(props) {
  const setMessage = useMessageDispatch();

  const tableRef = useRef(null);
  const [initialProducts, setInitialProducts] = useState([]);
  const [products, setProducts] = useState([ ...props.products ]);
  const [user, setUser] = useState({ ...props.dados });
  const [visible, setVisible] = useState({
    currentPassword: false,
  });
  const [isFormValid, setFormValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const widthTable = user.password?.length > -1 ? 568 : 500;

  useEffect(() => {
    setUser({ ...props.dados });

    const prod = products;
    if (props.open && prod && prod.length > 0 && props.dados.id) {
      setLoading(true);

      const id = props.dados.id;

      UserService.fetchProductsByUserId(id)
        .then(response => response.json())
        .then((result) => {
          // Salva carregamento do menu inicial para posterior conferencia
          setInitialProducts(result);

          const current = tableRef.current;
          const dataManager = current.dataManager;
          if (dataManager.data.length > 0) {
            result.forEach(item => {
              const row = dataManager.data.find(
                rowData => (rowData.id === item.id && !rowData.parentId)
              );
              if (row) {
                row.tableData.checked = true;
              }
              
              item?.items?.forEach(it => {
                const selectedRow = dataManager.data.find(
                  rowData => (rowData.id === it.id && rowData.parentId === item.itemId)
                );
                if (selectedRow) {
                  selectedRow.tableData.checked = true;
                  it?.items?.forEach(ite => {
                    const selectedRow = dataManager.data.find(
                      rowData => (rowData.id === ite.id && rowData.parentId === it.itemId)
                    );
                    if (selectedRow) {
                      selectedRow.tableData.checked = true;
                    }
                  });
                }
              });
            });

            current.setState(current.dataManager.getRenderState());
          }
          
          setLoading(false);
        });
    }
  }, [props.open]);

  useEffect(() => {
    const validPass = user.id === 0 ? user.password?.length > 0 : true;
    setFormValid(user.name?.length > 0 &&
      user.username?.length > 0 && user.email?.length > 0 && validPass);
  }, [user])
    
  const handleOnRegister = () => {
    setLoading(true);

    const prods = [];
    tableRef.current.dataManager.data.map((item) => {
      if (item.tableData.checked && !item.parentId) {
        const itens = [];
        if (item.tableData.childRows) {
          item.tableData.childRows.filter((iten) => iten.tableData.checked).forEach((iten) => {
            itens.push({ id: iten.itemId, name: iten.name });
            if (iten.tableData.childRows) {
              iten.tableData.childRows.filter((it) => it.tableData.checked).forEach((it) => {
                itens.push({ id: it.itemId, name: it.name });
              });
            }
          });
        }
        const it = { id: item.id, name: item.name, items: [ ...itens ] };
        prods.push(it);
      }
    });

    const saveUser = { ...user, products: [ ...prods ] };
    
    saveUser.id = saveUser.id === 0 ? null : saveUser.id;

    const oper = user.id === 0 ? 'criar' : 'alterar';

    UserService.registerUser(saveUser)
      .then(async (response) => {
        const result = await response.json();
        if (response.ok) {
          if (user.id !== 0) {
            sendNotification(user.id, prods);
          }

          props.onChange(result);
        }
        else {
          if (result.details) {
            setMessage({ hasError: true, text: `Usuário "${user.username}": ${result.details[0]}` });
          }
          else {
            setMessage({ hasError: true, text: `Não foi possível ${oper} o usuário "${user.username}" verifique sua conexão e tente novamente.` });
          }
        }
      })
      .catch(() => {
        setMessage({ hasError: true, text: `Não foi possível ${oper} o usuário "${user.username}" verifique sua conexão e tente novamente.` });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function sendNotification(userId, prods) {
    const mapItems = [];
    props.products.map((level1) => {
      level1.items?.map((level2 => {
        if (level2.items) {
          level2.items?.map((level3) => mapItems.push({ item: `${level1.label}/${level2.label}/${level3.label}`, id: level3.itemId}))
        } else {
          mapItems.push({ item: `${level1.label}/${level2.label}`, id: level2.itemId})
        }
      }))
    });

    const initIds = [];
    initialProducts.map((level1) => {
      initIds.push(level1.id);
      level1.items?.map((level2 => {
        initIds.push(level2.itemId);
        level2.items?.map((level3) => initIds.push(level3.itemId))
      }))
    });

    const ids = [];
    prods.map((level1) => {
      ids.push(level1.id);
      level1.items?.map((level2) => ids.push(level2.id))
    });

    const intersection = ids.filter((item) => !initIds.includes(item));

    const content = intersection.map((item) => {
      return mapItems.find((iten) => item === iten.id).item;
    }).join(",");

    if (content.length > 0) {
      NotificationService.menuNotification({ userId, content });
    }
  }
  
  const buttons = (
    <SaveButton
      loading={isLoading}
      disabled={!isFormValid}
      onClick={handleOnRegister}
      size="medium"
      sx={(theme) => ({ marginLeft: theme.spacing(2) })}
    >
      {isLoading ? 'Salvando' : 'Salvar'}
    </SaveButton>
  );

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleClickShowCurrentPassword = () => 
    setVisible({ currentPassword: !visible.currentPassword});

  const handleDrawerClose = () => {
    // setTimeout(() => { setMessage({ hasError: false, text: '' }); }, 10000);
    props.onCanvasClose(false);
  };

  const handleTextChange = (event) => {
    setUser({ ...user, [event.target.id]: event.target.value });
  }

  const onPasswordChange = (evt) =>
    setUser({ ...user, password: evt.target.value });

  const handleSelectChange = (rows, row) => {
    const current = tableRef.current;
    const dataManager = current.dataManager;
    if (row.parentId) {
      if (row.tableData.checked) {
        const selectedRow = dataManager.data.find(
          rowData => (rowData.id === row.parentId)
        );
        
        if (selectedRow && !selectedRow.tableData.checked) {
          selectedRow.tableData.checked = true;
          current.setState(current.dataManager.getRenderState());
        }
      }
    }
    else {
      row.tableData.childRows?.forEach(item => {
        item.tableData.checked = row.tableData.checked;
      });

      current.setState(current.dataManager.getRenderState());
    }
  }

  return (
    <OffCanvas 
      showPanel={props.open}
      onClosedPanel={handleDrawerClose}
      isLoading={isLoading}
      buttons={buttons} 
      header={{ title: "Usuário", add: user.id === 0, edit: user.id !== 0 }}
    >
      <Grid container spacing={2} sx={(theme) => ({ paddingTop: theme.spacing(3) })}>
        <Grid item xs={12}>
          <TextField
            id="username"
            name="username"
            label="Nome do usuário"
            type="text"
            value={user.username}
            onChange={handleTextChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="name"
            name="name"
            label="Nome"
            type="text"
            value={user.name}
            onChange={handleTextChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="email"
            name="email"
            label="E-mail"
            type="text"
            value={user.email}
            onChange={handleTextChange}
            fullWidth
            required
          />
        </Grid>
        {user.password?.length > -1 &&
          <Grid item xs={12}>
            <FormControl sx={{ width: '100%' }} variant="outlined">
              <InputLabel required htmlFor="password">Senha</InputLabel>
              <OutlinedInput
                fullWidth
                required
                id="pass"
                name="pass"
                type={visible.currentPassword ? 'text' : 'password'}
                label="Senha"
                value={user.password}
                onChange={onPasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {visible.currentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        }
        {products?.length > 0 &&
          <Grid item xs={12}>
            <MaterialTable
              title="Menu"
              tableRef={tableRef}
              columns={[
                { title: 'Nome', field: 'label' },
              ]}
              data={products}
              parentChildData={(row, rows) => rows.find(a => a.itemId === row.parentId)}
              onRowClick={(e, rowData) => tableRef.current.onTreeExpandChanged(rowData.tableData.path, rowData)}
              onSelectionChange={handleSelectChange}
              options={{
                ...DefaultOptions,
                maxBodyHeight: `calc(100vh - ${widthTable}px)`,
                paging: false,
                selection: true,
                showSelectAllCheckbox: false,
                showTextRowsSelected: false,
              }}
              localization={{ ...DefaultLocalization }}
            />
          </Grid>
        }
      </Grid>
    </OffCanvas>
  );
};
