import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

import SaveIcon from '@mui/icons-material/Save';

// type VariantType =  'text' | 'outlined' | 'contained' | undefined;
// type ColorType = 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
// type SizeType = 'small' | 'medium' | 'large' | undefined;

function SaveButton(props) {
  const { color, size, startIcon, variant, sx} = props;
  
  return (
    <LoadingButton
      loadingPosition="start"
      startIcon={props.startIcon || <SaveIcon />}
      color={color || 'primary'}
      size={size || 'small'}
      variant={variant || 'contained'}
      
      loading={props.loading}
      disabled={props.disabled}
      onClick={props.onClick}
      sx={sx}
      
      // fullWidth={props.fullWidth}
    >
      {props.children}
    </LoadingButton>
  )
};

export default SaveButton;
