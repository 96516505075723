import { NotificationEndpoints } from '../types/Endpoints';

import { getRequest } from './RequestService';

const extraHeaders = {
  'x-app': 'notification'
};

class NotificationService {
  countUnreadNotes(userId) {
    const API_URL = NotificationEndpoints.NOTIFICATION;
    return getRequest(`${API_URL}/user/${userId}/unread`, {}, extraHeaders);
  }
}

export default new NotificationService();
