import React, { useMemo, useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import NotificationsIcon from '@mui/icons-material/Notifications';

import {
  OffCanvas,
  Table,
  useMessageDispatch,
} from 'itaf';

import NotificationService from '../../services/NotificationService';

export default function NotificationCanvas(props) {
  const setMessage = useMessageDispatch();

  const [notifications, setNotifications] = useState([]);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isRefetching, setRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  
  useEffect(() => {
    if (props.open) {
      setPagination({ pageIndex: 0, pageSize: 10 });
      fetchData();
    }
  }, [props.open])

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    if (props.open) {
      fetchData();
    }
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const fetchData = async () => {
    if (!notifications.length) {
      setLoading(true);
    } else {
      setRefetching(true);
    }

    // url.searchParams.set('size', `${pagination.pageSize}`);
    // url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    // url.searchParams.set('globalFilter', globalFilter ?? '');
    // url.searchParams.set('sorting', JSON.stringify(sorting ?? []));

    const id = props.user.id;

    const page = {
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageIndex,
    }

    NotificationService.userNotifications(id, page)
    .then((response) => {
      if (response.ok) {
        (async () => {
          const result = await response.json();
          setNotifications(result.content);
          setRowCount(result.totalElements);
          setError(false);
        })();
      }
      else {
        setMessage({ hasError: true, text: 'Não foi possível carregar as notificações.' });
        setNotifications([]);
        setError(true);
      }
    })
    .catch((error) => {
      setError(true);
      console.error(error);
      setMessage({ hasError: true, text: 'Verifique se está logado e com conexão de internet.' });
    })
    .finally(() => {
      setLoading(false);
      setRefetching(false);
    });
  };

  const handleDrawerClose = () => {
    // setTimeout(() => { setMessage({ hasError: false, text: '' }); }, 10000);
    props.onCanvasClose(false);
  };

  const handleToggleExpand = (event, row) => {
    event.stopPropagation();
    row.toggleExpanded();
    if (!row.getIsExpanded() && !row.original.read) {
      NotificationService.markReadNote(row.original.id)
      .then((response) => {
        if (response.ok) {
          notifications[row.index] = { ...row.original, read: true };
          setNotifications([ ...notifications ]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

  const buildTable = () => {
    const columns = useMemo(
      () => [
        {
          id: 'read',
          header: 'Visualizado',
          accessorKey: "read", 
          columnDefType: 'display', //turns off data column features like sorting, filtering, etc.
          enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
          size: '30', maxSize: '30',
          enableColumnActions: false, enableColumnFilter: false, enableResizing: false,
          enableHiding: false,
          muiTableBodyCellProps: {
            align: 'center'
          },
          muiTableHeadCellProps: {
            align: 'center'
          },
          Cell: ({ renderedCellValue }) => (
            renderedCellValue ? <MarkEmailReadIcon /> : <MarkunreadIcon />
          ),
        },
        {
          header: "Assunto", accessorKey: "subject",
          enableColumnDragging: false,
          enableHiding: false,
        },
      ],
      []
    );

    const initial = {
      columnVisibility: { 'mrt-row-expand': false },
    };

    return <Table
      title='Notificações'
      columns={columns}
      data={notifications}
      isLoading={isLoading}
      enableHiding={false}
      enablePinning={false}
      pagination
      initialState={ initial }
      clickRowExpand={handleToggleExpand}
      details={({ row }) => (
        <Box
          sx={{
            display: 'grid',
            margin: 'auto',
            gridTemplateColumns: 'max-content',
            width: '100%',
          }}
        >
          <Typography>{row.original.content}</Typography>
        </Box>
      )}
    />;
  };

  return (
    <OffCanvas 
      showPanel={props.open}
      onClosedPanel={handleDrawerClose}
      isLoading={isLoading}
      header={{ title: `Notificações de ${props.user?.name}`, add: false, edit: false, icon: (<NotificationsIcon />) }}
      closeLabel="Fechar"
    >
      <Grid container spacing={2} sx={(theme) => ({ paddingTop: theme.spacing(3) })}>
        <Grid item xs={12}>
          {buildTable()}
        </Grid>
      </Grid>
    </OffCanvas>
  );
};
