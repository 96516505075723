const FrameOptions = {
  width: "100%",
  autoResize: false,
  scrolling: true,
  frameBorder: "0",
  checkOrigin: true,
  style: {
    borderRadius: "4px", maxHeight: "89vh", minHeight: "89vh",
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'
  }
};

export default FrameOptions;
