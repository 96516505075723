import { CadastroEndpoints } from '../types/Endpoints';

import { RequestService } from 'itaf';

const extraHeaders = {
  'x-app': 'bridge'
};

class CadastroService {
  fetchDashboardCnpjData() {
    const API_URL = CadastroEndpoints.Dashboard;
    return RequestService.getRequest(`${API_URL}`, {}, extraHeaders);
  }
}

export default new CadastroService();
