import React, { Fragment } from 'react';
import styled from '@emotion/styled'
import {
	Avatar,
	Box,
	Grid,
	Link,
	Paper,
	Typography,
} from "@mui/material";

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import MessageBar from '../../components/MessageBar';

const BackgroundImage = styled(Grid)`
	background-image: url("https://www.spedsummer.com.br/itaf_login_image.png");
	background-repeat: no-repeat;
	background-size: cover;
`

const LoginTemplate = (props) => {

	return (
		<Fragment>
			<MessageBar />
			<Grid container>
				<BackgroundImage item xs={false} sm={4} md={7} />
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<Box display="flex" flexDirection="column" justifyContent="center" px={6} height="100vh">
						<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" pb={4}>
							<Avatar title="Account Avatar" sx={(theme) => ({ backgroundColor: theme.palette.primary.main })}>
								<LockOutlinedIcon />
							</Avatar>
							<Box pt={1}>
								<Typography component="h1" variant="h5">{props.title}</Typography>
							</Box>
						</Box>
						{props.children}
						<Box py={3}>
							<Typography variant="body2" color="textSecondary" align="center">
								E-mail: <Link color="inherit" href="mailto:tania.gurgel@uol.com.br?Subject=Contato%20iTAF&Body=Contato%20atrav%E9s%20da%20p%E1gina%20do%20iTAF%20%28spedsummer%29">tania.gurgel@uol.com.br</Link>
							</Typography>
							<p></p>
							<Typography variant="body2" color="textSecondary" align="center">
								Telefone: (11) 98181-4045
							</Typography>
							<p></p>
							<Typography variant="body2" color="textSecondary" align="center">
								Copyright ©
								<Link color="inherit" href="https://itaf.google.com/">
									iTAF 2020
								</Link>{' '}
							</Typography>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default LoginTemplate;
