const ResponseStatus = Object.freeze({
  OK: 200,
  CREATED: 201,
  TEMPORARY_REDIRECT: 307,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
});

export default ResponseStatus;
