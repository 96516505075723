import React from 'react';

import RegisterContainer from '../../containers/Register';

const RegisterPage = () => {
  
  return (
    <RegisterContainer />
  );
};

export default RegisterPage;
