import RequestMethod from '../types/RequestMethod';

const defaultHeaders = {
  'Content-Type': 'application/json',
}

const buildQueryParams = (params) => {
  const keys = Object.keys(params);
  if (!params || keys.length === 0) {
    return '';
  }
  return keys.reduce((acc, curr) => {
    const builtParam = `${curr}=${params[curr]}`;
    if (acc === '') {
      return builtParam;
    }
    return `${acc}&${builtParam}`;
  }, '');
};

export const getRequest = async (url, queryParams = {}, extraHeaders = {}) => {
  const builtUrl = Object.keys(queryParams).length !== 0 ? `${url}?${buildQueryParams(queryParams)}` : url;
  return fetch(builtUrl, {
    method: RequestMethod.GET,
    credentials: 'include',
    headers: { ...defaultHeaders, ...extraHeaders },
  });
};

export const postRequest = async (url, body = {}, queryParams = {}, extraHeaders = {}) => {
  const builtUrl = Object.keys(queryParams).length !== 0 ? `${url}?${buildQueryParams(queryParams)}` : url;
  return fetch(builtUrl, {
    method: RequestMethod.POST,
    body: JSON.stringify(body),
    credentials: 'include',
    headers: { ...defaultHeaders, ...extraHeaders },
  });
};

export const putRequest = async (url, body = {}, queryParams = {}, extraHeaders = {}) => {
  const builtUrl = Object.keys(queryParams).length !== 0 ? `${url}?${buildQueryParams(queryParams)}` : url;
  return fetch(builtUrl, {
    method: RequestMethod.PUT,
    body: JSON.stringify(body),
    credentials: 'include',
    headers: { ...defaultHeaders, ...extraHeaders },
  });
};

export const deleteRequest = async (url, queryParams = {}, extraHeaders = {}) => {
  const builtUrl = `${url}?${buildQueryParams(queryParams)}`;
  return fetch(builtUrl, {
    method: RequestMethod.DELETE,
    credentials: 'include',
    headers: { ...extraHeaders },
  });
};

export const getPageableRequest = async (url, page, queryParams = {}, extraHeaders = {}) => {
  const params = { ...page, ...queryParams };
  const builtUrl = `${url}?${buildQueryParams(params)}`;
  return fetch(builtUrl, {
    method: RequestMethod.GET,
    credentials: 'include',
    headers: { ...defaultHeaders, ...extraHeaders },
  });
};

const RequestService = {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  getPageableRequest,
};

export default RequestService;
