import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import {
	AppBar,
	Avatar,
	Badge,
	Box,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

import {
	AccountCircle as AccountCircleIcon,
	ChevronLeft as ChevronLeftIcon,
	ExitToApp as ExitToAppIcon,
	Menu as MenuIcon,
	Notifications as NotificationsIcon,
} from '@mui/icons-material';

// import DrawerItems from "./DrawerItems";
import MessageBar from '../../components/MessageBar';

import { signOut, getUser } from "../../context/UserContext";

import { notificationHook } from "../../hooks/useLocalStorage";

import http from '../../services/AuthService';
import NotificationService from '../../services/NotificationService';

const openedMixin = (theme) => ({
  width: theme.spacing(24),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: theme.spacing(24),
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const ToolbarDiv = styled(Toolbar)(
  ({ theme }) => ({
    ...theme.mixins.toolbar
  }),
);

const MainTemplate = (props) => {
	const history = useNavigate();
	// const { user } = useUserState();
	
	const [menu, setMenu] = useState(null);
	const [open, setOpen] = React.useState(false);
	const [page, setPage] = React.useState("Dashboard");
	const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
	const profileMenuOpen = Boolean(menuAnchorEl);
	const [user, setUser] = React.useState("User");
	const [notesCount, setNotesCount] = notificationHook();

  const toggleDrawer = () => setOpen(!open);
	const closeProfileMenu = () => setMenuAnchorEl(null);
  const openProfileMenu = (event) => setMenuAnchorEl(event.currentTarget);
	
	const getToolbarItems = () => {
		const userProducts = user.products;
		const items = [];
	
		const itemsName = props.menu.flatMap(item => item.productName);
		userProducts?.forEach((product) => {
			if (itemsName?.includes(product.name)) {
				const i = itemsName.indexOf(product.name);
				const item = { ...props.menu[i], label: product.label }
				items.push(item);
			}
		});
	
		return items;
	};

	useEffect(() => {
		const localUser = getUser();
		if (localUser) {
			setUser(localUser);
			// setMenu(getToolbarItems());
			// NotificationService.countUnreadNotes(localUser.id)
			// .then((resp) => resp.text())
			// .then((data) => {
			// 	setNotes(data);
			// });
		}
	}, []);

	useEffect(() => {
		setMenu(getToolbarItems());
	}, [user]);

	useEffect(() => {
		setNotesCount(props.notesCount);
	}, [props.notesCount]);

	function fetchNotesCount() {
    NotificationService.countUnreadNotes(user.id)
    .then((resp) => resp.text())
    .then((data) => {
      setNotesCount(data);
      // localStorage.setItem("notes_count", `${data}`);
    });
  }
	
	function handleLogoutClick() {
		// setMessage({ hasError: false, messageError: '' });

		http.logout()
			.then(() => {
				signOut(history);
				history("/login");
			})
			.catch(() => {
				// setMessage({ hasError: true, messageError: 'Problema ao registrar a saída' });
				signOut(history);
				history("/login");
			});
	}

	function changedPage(newPage) {
    setPage(newPage);
		fetchNotesCount();
		// props.selectedPage()
  }

	function profileMenuClick(index) {
		closeProfileMenu();
		props.profileMenu[index].action();
	}

	return (
		<Box display="flex">
			<AppBar position="fixed" sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}>
				<Toolbar style={{ paddingLeft: '16px', paddingRight: '16px' }}>
					<IconButton
						color="inherit"
						aria-label="abrir sidebar"
						onClick={toggleDrawer}
						edge="start"
					>
						{!open ? <MenuIcon /> : <ChevronLeftIcon />}
					</IconButton>
					<Box>
						<Typography variant="h6" noWrap>
							{page}
						</Typography>
					</Box>
					<Box pl={2} flexGrow={1}>
						<Typography variant="h6" noWrap style={{ textAlign: "center" }}>
							iTAF - Inteligência Tributária, Administrativa e Financeira {props.app}
						</Typography>
					</Box>
					{props.openNotification && 
						<Tooltip title="Notificação">
							<Badge badgeContent={parseInt(notesCount)} color="info" overlap="circular" max={99}
								onClick={props.openNotification}
								sx={{ cursor: 'pointer' }}
							>
								<NotificationsIcon color="inherit" />
							</Badge>
						</Tooltip>
					}
					<Tooltip title="Perfil">
						<IconButton
							color="inherit"
							aria-label="area usuario"
						  onClick={openProfileMenu}
						>
							<AccountCircleIcon />
							<Box pl={1}>
								<Typography variant="h6">{user?.name || ""}</Typography>
							</Box>
						</IconButton>
					</Tooltip>
					{props.profileMenu && 
						<Menu
							anchorEl={menuAnchorEl}
							// anchorOrigin={{ vertical: "top", horizontal: "right" }}
							// transformOrigin={{ vertical: "top", horizontal: "right" }}
							open={profileMenuOpen}
							onClose={closeProfileMenu}
							keepMounted
						>
							{props.profileMenu.map((item, index) =>
								<MenuItem key={index} onClick={(e) => profileMenuClick(e.target.value)}>{item.label}</MenuItem>
							)}
							{/* <MenuItem onClick={handleProfileClick}>Perfil</MenuItem> */}
							{/* <MenuItem onClick={handleLogoutClick}>Sair</MenuItem> */}
						</Menu>
					}
					<Tooltip title="Sair">
						<IconButton color="inherit" aria-label="Sair" component="span" onClick={handleLogoutClick}>
							<ExitToAppIcon />
						</IconButton>
					</Tooltip>
				</Toolbar>
			</AppBar>
			{menu && 
				<Drawer variant="permanent" open={open}>
					<ToolbarDiv />
					<List>
						{menu.map((item, index) =>
							<ListItem
								button
								component={NavLink}
								to={item.link}
								key={index}
								onClick={() => changedPage(item.label)}
								>
								<ListItemIcon sx={(theme) => ({ minWidth: theme.spacing(5) })}>
									{item.icon}
								</ListItemIcon>
								<ListItemText primary={item.label} />
							</ListItem>
						)}
					</List>
				</Drawer>
			}

			<Box px={2} py={2} flexGrow={1}>
				<ToolbarDiv />
				<MessageBar />
				{props.children}
			</Box>

			<Avatar
				alt="iTAF Robot"
				src="/robo_itaf.png"
				sx={{ position: 'fixed', bottom: '1vh', borderRadius: 'unset',
				  width: 96, height: 96, zIndex: 2000,
				  'MuiAvatar-img': {zIndex: 3000}
				}}
			/>
		</Box>
	);
};

export default MainTemplate;
