import React, { useState, useEffect } from 'react';
import { 
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
  OffCanvas,
  SaveButton,
  useMessageDispatch,
} from 'itaf';

import UserService from '../../services/UserService';

export default function NewPasswordCanvas(props) {
  const setMessage = useMessageDispatch();

  const [state, setState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [visible, setVisible] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [isFormValid, setFormValid] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setFormValid(state.currentPassword?.length > 0 &&
      state.newPassword?.length > 5 && state.confirmPassword?.length > 5 &&
      state.newPassword === state.confirmPassword
      );
  }, [state])

  const handleOnRegister = () => {
    setLoading(true);
    
    const errorText = "Não foi possível alterar a senha verifique que a senha nova é diferente da antiga e a confirmação de senha está correta";
    UserService.changePassaword({
      oldPassword: state.currentPassword,
      newPassword: state.newPassword,
    })
      .then((response) => {
        if (response.ok) {
          handleDrawerClose();
        }
        else {
          setMessage({ hasError: true, text: errorText });
        }
      })
      .catch(() => {
        setMessage({ hasError: true, text: errorText });
      })
      .finally(() => {
        setLoading(false);
      });
  }
  
  const buttons = (
    <SaveButton
      loading={isLoading}
      disabled={!isFormValid}
      onClick={handleOnRegister}
      size="medium"
      sx={(theme) => ({ marginLeft: theme.spacing(2) })}
    >
      {isLoading ? 'Salvando' : 'Salvar'}
    </SaveButton>
  );

  const handleDrawerClose = () => {
    // setTimeout(() => { setMessage({ hasError: false, text: '' }); }, 10000);
    props.onCanvasClose(false);
  };

  const onCurrentPasswordChange = (evt) =>
    setState({ ...state, currentPassword: evt.target.value });
  const onNewPasswordChange = (evt) =>
    setState({ ...state, newPassword: evt.target.value });
  const onConfirmPasswordChange = (evt) =>
    setState({ ...state, confirmPassword: evt.target.value });

  const handleClickShowCurrentPassword = () => {
    setVisible({
      ...visible,
      currentPassword: !visible.currentPassword,
    });
  };

  const handleClickShowNewPassword = () => {
    setVisible({
      ...visible,
      newPassword: !visible.newPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setVisible({
      ...visible,
      confirmPassword: !visible.confirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <OffCanvas 
      widthSm
      showPanel={props.open}
      onClosedPanel={handleDrawerClose}
      isLoading={isLoading}
      buttons={buttons} 
      header={{ title: `Alteração de senha (${props.user?.name})`, add: false, edit: true }}
    >
      <Grid container spacing={2} sx={(theme) => ({ paddingTop: theme.spacing(3) })}>
        <Grid item xs={12}>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor="atual-password">Senha atual</InputLabel>
            <OutlinedInput
              fullWidth
              required
              id="atual-password"
              name="atual-password"
              type={visible.currentPassword ? 'text' : 'password'}
              label="Senha atual"
              autoComplete="atual"
              value={state.currentPassword}
              onChange={onCurrentPasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowCurrentPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visible.currentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor="new-password">Nova senha</InputLabel>
            <OutlinedInput
              fullWidth
              required
              id="new-password"
              type={visible.newPassword ? 'text' : 'password'}
              label="Nova senha"
              value={state.newPassword}
              onChange={onNewPasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visible.newPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor="confirm-password">Confirmação de nova senha</InputLabel>
            <OutlinedInput
              fullWidth
              required
              id="confirm-password"
              type={visible.confirmPassword ? 'text' : 'password'}
              label="Confirmação da nova senha"
              value={state.confirmPassword}
              onChange={onConfirmPasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visible.confirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </OffCanvas>
  );
};
