import React from 'react';

import DashboardContainer from '../../containers/Dashboard';

const DashboardPage = () => {
  
  return (
    <DashboardContainer />
  );
};

export default DashboardPage;
