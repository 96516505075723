import React, { useRef } from 'react';
import IframeResizer from 'iframe-resizer-react';

import FrameOptions from '../FrameOptions';

const ReportPage = (props) => {
  const ref = useRef(null);
  
  return (
    <>
      <IframeResizer
        id="report"
        // log
        forwardRef={ref}
        src={props.url}
        onMessage={ props.onMessage }
        inPageLinks
        { ...FrameOptions }
//        iframeResizerEnable={true}
//        autoResize={true}
//        heightCalculationMethod="max"
//        resizedCallback={ () => { console.log("resized!"); }}
      />
    </>
  );
};

export default ReportPage;
