import React, {useEffect, useState} from 'react';

import { useTheme } from '@nivo/core';
import { ResponsiveSunburst } from '@nivo/sunburst'

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@mui/material';

import Legend from './Legend';

export default function SunburstChartWidget({ data, title}) {
  const [actualData, setActualData] = useState(null);
  const [checked, setChecked] = React.useState(true);
  const separator = "#";
  var count = 1;

  useEffect(() => {
    setIndexName(data, count);
    setActualData(data);
  }, [data]);
  
  function setIndexName(actual) {
    actual.name = `${count}${separator}${actual.name}`;
    count += 1;
    actual.children?.forEach((item) => {
      setIndexName(item);
  });
  }

  const legendHandleChange = (event) => {
    setChecked(event.target.checked);
  };

  const flatten = data =>
    data.reduce((acc, item) => {
      if (item.children) {
        return [...acc, item, ...flatten(item.children)]
      }

      return [...acc, item]
    }, []);

  const findObject = (data, name) => data.find(searchedName => searchedName.name === name);

  const CustomTooltip = ({ id, value, color }) => {
    const theme = useTheme();

    function getName(id) {
      return id.substring(id.lastIndexOf(separator) + 1, id.length);
    }

    return (
      <strong style={{ ...theme?.tooltip?.container, backgroundColor: color, color: '#333' }}>
        {getName(id)}: {Number.parseFloat(`${value}`).toLocaleString('pt-BR')}
      </strong>
    )
  }

  const CenteredMetric = ({ nodes, centerX, centerY }) => {
    const total = nodes.reduce((total, datum) => datum.depth === 1 ? total + datum.value : total + 0, 0)

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
            fontSize: '20px',
            fontWeight: 600,
        }}
      >
        {Number.parseFloat(`${total}`).toLocaleString('pt-BR')}
      </text>
    )
  }

  if (actualData) {
    return (
      <Card>
        <CardHeader 
          title={title}
          action={
            <Button variant="contained" onClick={() => setActualData(data)}>Dados iniciais</Button>
          }
          subheader={<FormGroup><FormControlLabel control={<Switch checked={checked}
          onChange={legendHandleChange} />} label="Exibe legenda" /></FormGroup>}
        />
        <CardContent sx={{ paddingTop: 0}}>
          <Grid container>
            {checked && <Grid item sm={4} xs={12}>
              <Legend data={actualData}/>
            </Grid>}
            <Grid item sm={checked ? 8 : 12} xs={12} sx={{ height: 250 }}>
              <ResponsiveSunburst
                data={actualData}
                id={'name'}
                value={'value'}
                colors={{ datum: 'data.color' }}
                layers={['arcs', 'arcLabels', CenteredMetric]}
                style={{width:'75%'}}
                borderWidth={1}
                animate={true}
                childColor={(parent, child) => {
                  return child.data.color;
                }}
                motionConfig={'gentle'}
                enableArcLabels
                arcLabel={(data) => {
                  if (data.depth === 1) {
                    return data.formattedValue;
                  }
                  return '';
                }}
                arcLabelsSkipAngle={12}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 3]],
                }}
                transitionMode="pushIn"
                onClick={clickedData => {
                  const foundObject = findObject(flatten(actualData.children), clickedData.id);
                  if (foundObject && foundObject.children) {
                    setActualData(foundObject);
                  }
                }}
                tooltip={CustomTooltip}
                theme={{
                    tooltip: {
                        container: {
                            background: '#fff',
                        },
                    },
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (<div></div>);
}
