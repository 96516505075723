import React from 'react';

import { MessageProvider, UserProvider } from 'itaf';

export default function CommonProvider({ children }) {
  return (
    <UserProvider>
      <MessageProvider>
        {children}
      </MessageProvider>
    </UserProvider>
  );
}