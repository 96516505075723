import React, { useState, useEffect } from 'react';
import RegisterCanvas from './RegisterCanvas';
import CustomerCanvas from './CustomerCanvas';

import MaterialTable from '@material-table/core';
// import MaterialTable from 'material-table';

import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded';

import { 
  DefaultLocalization,
  DefaultOptions,
  ResponseStatus,
  useMessageDispatch,
} from 'itaf';

import CustomerService from '../../services/CustomerService';
import UserService from '../../services/UserService';

const newUser = {
  id: 0,
  name: '',
  username: '',
  email: '',
};

export default function RegisterContainer(props) {
  const setMessage = useMessageDispatch();

  const [open, setOpen] = useState(false);
  const [dados, setDados] = useState([]);
  const [user, setUser] = useState({ ...newUser });
  const [oldUser, setOldUser] = useState({ ...newUser });
  const [isLoading, setLoading] =  useState(false);
  const [customers, setCustomers] = useState([]);
  const [openCustomer, setOpenCustomer] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoading(true);

    loadProducts();

    loadCustomers();

    loadUsers();
  }, []);

  function loadUsers() {
    UserService.fetchRegisteredUsers()
      .then((response) => {
        if (ResponseStatus.OK === response.status) {
          (async () => {
            const result = await response.json();
            setDados(result);
          })();
        }
        else {
          setMessage({ hasError: true, text: 'Não foi possível carregar usuários cadastrados.' });
          setDados([]);
        }
      })
      .catch(() => {
        setMessage({ hasError: true, text: 'Verifique se está logado e com conexão de internet.' });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function loadProducts() {
    const errorText = "Não foi possível buscar todos os produtos disponíveis, ainda é possível terminar o cadastro e liberar acesso aos produtos depois!";
    UserService.fetchProducts()
      .then((response) => {
        if (ResponseStatus.OK === response.status) {
          (async () => {
            const result = await response.json();
            const items = [];
            result.map(function (item) {
              items.push(item);
            });
            result.map(function (item) {
              setParentId(item, items);
            });

            setProducts(items);
          })();
        }
        else {
          setMessage({ hasError: true, text: errorText });
          setProducts(null);
        }
      })
      .catch(() => {
        setMessage({ hasError: true, text: 'Verifique se está logado e com conexão de internet.' });
      });
  }

  function setParentId(item, items) {
    const children = item.itens || item.items;
    children.map(function (it) {
      // items.push({ ...it, parentId: item.id });
      items.push({ ...it });
      if (it.itens || it.items) {
        setParentId(it, items);
      }
    });
  }

  function loadCustomers() {
    CustomerService.getCustomers()
      .then((response) => {
        if (ResponseStatus.OK === response.status) {
          return response.json();
        }

        return ([]);
      })
      .then((result) => {
        setCustomers(result);
      });
  }
  
  const getDataCanvas = (value) => {
    if (user.id) {
      const index = dados.findIndex(item => item.id === oldUser.id);
      if (index !== -1) {
        dados[index] = value;
      }
    }
    else {
      setDados([value, ...dados]);
    }
    const operacao = user.id ? 'alterado' : 'cadastrado';
    setMessage({ hasError: false, text: `Usuário ${operacao} com sucesso` });
    setOpen(false);
  };

  const updatedCustomer = () => {
    setMessage({ hasError: false, text: 'Usuário atualizado com sucesso' });
    setOpenCustomer(false);
  };

  const buildTable = () => (
    <MaterialTable
      title="Usuários"
      // tableRef={this.tableRef}
      isLoading={isLoading}
      columns={[
        { title: 'Nome', field: 'name' },
        { title: 'Usuário', field: 'username' },
        { title: 'E-mail', field: 'email' },
      ]}
      data={dados}
      actions={[
        {
          icon: 'add_box',
          tooltip: 'Novo usuário',
          isFreeAction: true,
          onClick: (event) => { setUser({ ...newUser, password: '' }); setOpen(true); }
        },
        {
          icon: 'edit',
          tooltip: 'Altera usuário',
          onClick: (event, row) => { setOldUser(row); setUser(row); setOpen(true); }
        },
        {
          icon: () => <ContactsRoundedIcon />,
          tooltip: 'Seleciona clientes',
          onClick: (event, row) => { setOldUser(row); setUser(row); setOpenCustomer(true); }
        },
      ]}
      options={{ ...DefaultOptions }}
      localization={{ ...DefaultLocalization }}
    />
  );

  return (
    <>
      { props?.breadcrumb ? (props.breadcrumb)
        : <div />
      }
      <div style={{ marginTop: 16 }}>
        {buildTable()}
      </div>

      {products?.length > 0 && 
        <RegisterCanvas
          open={open}
          onCanvasClose={(oper) => setOpen(oper)}
          dados={user}
          products={products}
          onChange={getDataCanvas}
        />
      }

      {customers?.length > 0 && 
        <CustomerCanvas
          open={openCustomer}
          onCanvasClose={(oper) => setOpenCustomer(oper)}
          dados={customers}
          user={user}
          onChange={updatedCustomer}
        />
      }
    </>
  );
};
