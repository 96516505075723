import { NotificationEndpoints } from '../types/Endpoints';

import { RequestService } from 'itaf';

const extraHeaders = {
  'x-app': 'notification'
};

class NotificationService {
  markReadNote(id) {
    const API_URL = NotificationEndpoints.NOTIFICATION;
    return RequestService.putRequest(`${API_URL}/${id}/read`, {}, {}, extraHeaders);
  }
  
  menuNotification(note) {
    const API_URL = NotificationEndpoints.NOTIFICATION;
    return RequestService.postRequest(`${API_URL}/menu`, note, {}, extraHeaders);
  }
  
  userNotifications(userId, page) {
    const API_URL = NotificationEndpoints.NOTIFICATION;
    return RequestService.getPageableRequest(`${API_URL}/user/${userId}`, page, {}, extraHeaders);
  }
}

export default new NotificationService();
