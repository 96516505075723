import React from 'react';
import {
  Card,
  CardActionArea,
  CardHeader,
  CardContent,
  Grid
} from '@mui/material';

const Widget = (props) => {
  const data = props?.data || [];

  return (
    <Card>
      <CardActionArea>
        <CardHeader title={props.title} />
        <CardContent>
          {data.map(function (item, index) {
            return (
              <Grid container spacing={2} key={index}>
                <Grid item xs={6}>
                  {item.label}
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }} >
                  {item.value?.toLocaleString("pt-BR")}
                </Grid>
              </Grid>
            )
          })}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Widget;
