import React from 'react';

import {
  IconButton, 
  Tooltip,
} from '@mui/material';

const commonIconButtonStyles = {
  height: '2rem',
  ml: '10px',
  opacity: 0.5,
  transition: 'opacity 150ms',
  width: '2rem',
  '&:hover': {
    opacity: 1,
  },
};

function TableIconButton(props) {
  const { tooltip, disabled } = props;
  
  if (tooltip && !disabled) {
    return (
      <Tooltip arrow placement="top" title={tooltip}>
        <IconButton 
          aria-label={tooltip}
          sx={commonIconButtonStyles}
          onClick={(event) => props.onClick(event)}
        >
          {props.children}
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <IconButton 
      aria-label="iconButton"
      disabled={disabled}
      sx={commonIconButtonStyles}
      onClick={(event) => props.onClick(event)}
    >
      {props.children}
    </IconButton>
  )
};

export default TableIconButton;
