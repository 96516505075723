import React from "react";

import { Typography } from "@mui/material";

import LegendButton from "./LegendButton";

export default function Legend ({ data }) {
  
  return (
    <div style={{ display: "grid" }}>
      <Typography gutterBottom variant="h4" component="div">
        Legenda
      </Typography>
      {data.children?.map((item) => (
        <LegendButton
          key={item.name}
          item={item}
        />
      ))}
    </div>
  );
};