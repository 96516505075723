import React, { useRef } from 'react';
import IframeResizer from 'iframe-resizer-react';

import FrameOptions from '../FrameOptions';

const ControllershipPage = (props) => {
  const ref = useRef(null);

  return (
    <>
      <IframeResizer
        id="control"
        // log
        forwardRef={ref}
        src={props.url}
        onMessage={ props.onMessage }
        inPageLinks
        { ...FrameOptions }
      />
    </>
  );
};

export default ControllershipPage;
