const DefaultOptions = {
	actionsColumnIndex: -1,
	columnsButton: false,
	// exportButton: true,
	search: true,
	paging: true,
	toolbar: true,
	exportAllData: true,
	pageSize: 10,
	pageSizeOptions: [5, 10, 25, 50, 100],
	// paginationType: 'stepped',
	addRowPosition: 'first',
	headerStyle: { position: 'sticky', zIndex: 'inherit' },
	maxBodyHeight: 600,
	rowStyle: (data, index) => {
		if (index % 2) {
		  return { backgroundColor: "#F6F7FF", borderBottom: '1px solid rgba(0,0,0,0.2)' };
		} else {
		  return { borderBottom: '1px solid rgba(0,0,0,0.2)' };
		}
	},
};

export default DefaultOptions;