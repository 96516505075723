export const cpfCnpjFormat = (value) => (value.length === 11)
  ? cpfFormat(value)
  : cnpjFormat(value);

export const cnpjFormat = (value) => (value)
  ? value.replace(/[^\d]+/g,'')
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
  : '';

export const cpfFormat = (value) => (value)
  ? value.replace(/[^\d]+/g,'')
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
  : '';

export const cnaeFormat = (value) => (value)
  ? value.replace(/[^\d]+/g,'')
    .replace(/(\d{4})(\d{1})(\d{2})/g, '$1-$2/$3')
  : '';

export const cepFormat = (value) => (value)
  ? value.replace(/[^\d]+/g,'')
    .replace(/(\d{2})(\d{3})(\d{3})/g, '$1.$2-$3')
  : '';
