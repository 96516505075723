import React from "react";

const MessageStateContext = React.createContext();
const MessageDispatchContext = React.createContext();

function MessageProvider({ children }) {
  const [data, setData] = React.useState({
    error: false,
    message: '',
  });

  const setMessage = React.useCallback(({ hasError, text }) => {
    setData({ error: hasError, message: text });
  }, []);

  return (
    <MessageStateContext.Provider value={data}>
      <MessageDispatchContext.Provider value={setMessage}>
        {children}
      </MessageDispatchContext.Provider>
    </MessageStateContext.Provider>
  );
}

function useMessageState() {
  const context = React.useContext(MessageStateContext);

  if (context === undefined) {
    throw new Error("useMessageState must be used within a MessageProvider");
  }

  return context;
}

function useMessageDispatch() {
  const context = React.useContext(MessageDispatchContext);

  if (context === undefined) {
    throw new Error("useMessageDispatch must be used within a MessageProvider");
  }

  return context;
}

export { MessageProvider, useMessageState, useMessageDispatch };

// ###########################################################
