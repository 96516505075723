import React, { useState, useEffect, Fragment } from "react";

import {
	Slide,
	Snackbar,
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';

import { useMessageState } from "../../context/MessageContext";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export default function MessageBar(props) {
	const messageState = useMessageState();

	const [isError, setError] = useState(false);
	const [message, setMessage] = useState('');
	const open = message?.length > 0;

	useEffect(() => {
		if (messageState?.message) {
			setMessage(messageState.message || '');
			setError(messageState.error);
    }
  }, [messageState])

	useEffect(() => {
		setMessage(props.message);
		setError(props.error);
	}, [props.message]);

	const handleClose = () => {
		setMessage('');
	};

	return (
    <Fragment>
			{message?.length > 0 &&
				<Snackbar 
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					autoHideDuration={6000} 
					TransitionComponent={SlideTransition}
					open={open} 
					onClose={handleClose}
				>
					<Alert onClose={handleClose} severity={isError ? "error" : "success"} sx={{ width: '100%' }}>
						{message}
					</Alert>
				</Snackbar>
			}
		</Fragment>
	)
}
