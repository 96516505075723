/* eslint-disable no-nested-ternary */
import React from 'react';

import { styled } from '@mui/material/styles';
import { 
	AppBar,
  Breadcrumbs,
	Chip,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
} from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export default function ModuleBreadcrumb({title, menu, onMenuSelected, moreItems}) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleMenuItemClick = (event, index) => {
    if (index > -1) {
      setSelectedIndex(index);
      onMenuSelected(menu[index]);
    }
  };

  function getBreadcrumbs() {
    if (menu) {
      return (
        <StyledToggleButtonGroup
          color="primary"
          value={selectedIndex}
          exclusive
          onChange={handleMenuItemClick}
        >
          {menu.map((option, index) => (
            <ToggleButton value={index} key={index}>
              {selectedIndex == index
                ? <Chip label={option.label} color="primary" />
                : <Chip label={option.label} color="primary" variant="outlined" />}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      );
    }

    return (
      <Breadcrumbs aria-label="breadcrumb" style={{ padding: 8}}>
        <Chip label={title} color="primary" />
      </Breadcrumbs>
    );
  }

  function getMoreItems() {
    if (moreItems) {
      return (
        <Grid item xs={4}>
          { moreItems }
        </Grid>
      );
    }
  }

  return (
    <AppBar position="sticky" style={{ backgroundColor: 'white' }}>
      <Toolbar style={{ padding: '0 16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={moreItems ? 8 : 12} style={{ display: 'flex' }}>
            { getBreadcrumbs() }
          </Grid>
          { getMoreItems() }
        </Grid>
      </Toolbar>
    </AppBar>
  );
}