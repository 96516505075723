import React, { useEffect, useRef, useState } from 'react';

import { 
  Box,
} from '@mui/material';
import MaterialTable from '@material-table/core';

import { 
  DefaultLocalization,
  DefaultOptions,
  OffCanvas,
  SaveButton,
  cpfCnpjFormat,
  useMessageDispatch,
} from 'itaf';

import CustomerService from '../../services/CustomerService';

export default function CustomerCanvas(props) {
  const tableRef = useRef();
  const [customers, setCustomers] = useState([ ...props.dados ]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [hasSelected, setSelected] = useState(true);

  const setMessageLocal = useMessageDispatch();
  function setMessage(message) {
    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage(message);
    }
  
    setMessageLocal(message);
  }
  
  useEffect(() => {
    if (props.open) {
      setLoading(true);

      const dados = props.dados;
      setCustomers(dados);

      const id = props.user.id;
      
      CustomerService.getCustomersByUserId(id)
        .then(response => response.json())
        .then((result) => {
          const current = tableRef.current;
          const dataManager = current.dataManager;
          if (dataManager.data.length > 0 && result) {
            result.forEach(item => {
              const row = dataManager.data.find(
                rowData => (rowData.id === item.customerId)
              );
              if (row) {
                row.tableData.checked = true;
              }
            });

            current.setState(current.dataManager.getRenderState());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.open]);
  
  const handleOnRegister = () => {
    setLoading(true);
    
    try {
      const save = {
        userId: props.user.id,
        customers: selectedCustomers.flatMap(item => item.id),
      };

      CustomerService.postCustomerUser(save)
        .then((response) => {
          if (response.ok) {
            props.onChange();
          }
          else {
            setMessage({ hasError: true, text: "Problema ao salvar clientes." });
          }
        })
        .catch (() => {
          setMessage({ hasError: true, text: "Problema ao salvar clientes." });
        })
        .finally (() => {
          setLoading(false);
        });
    }
    catch (e) {
      setMessage({ hasError: true, text: "Problema ao salvar clientes." });
    }
    finally {
      setLoading(false);
    }
  }
  
  const buttons = (
    <SaveButton
      loading={isLoading}
      disabled={hasSelected}
      onClick={handleOnRegister}
      size="medium"
      sx={(theme) => ({ marginLeft: theme.spacing(2) })}
    >
      {!hasSelected && isLoading ? 'Salvando' : 'Salvar'}
    </SaveButton>
  );

  const handleDrawerClose = () => {
    setLoading(true);
    setSelectedCustomers([]);
    // setTimeout(() => { setMessage({ hasError: false, text: '' }); }, 10000);
    props.onCanvasClose(false);
  };

  const handleSelectChange = (rows, row) => {
    setSelected(false);
    setSelectedCustomers(rows);
  }

  return (
    <OffCanvas 
      showPanel={props.open}
      onClosedPanel={handleDrawerClose}
      isLoading={isLoading}
      buttons={buttons} 
      header={{ title: `Usuário - Clientes (${props.user.name})`, add: false, edit: true }}
    >
      <Box>
        <MaterialTable
          title="Clientes"
          tableRef={tableRef}
          columns={[
            { title: "CNPJ/CPF", field: "cnpj", removable: false, width: '15%',
              render: rowData => cpfCnpjFormat(rowData.cnpj)
            },
            { title: "Nome", field: "nome", removable: false,
            },
          ]}
          data={customers}
          onSelectionChange={handleSelectChange}
          options={{
            ...DefaultOptions,
            maxBodyHeight: "calc(100vh - 256px)",
            paging: false,
            selection: true,
            // showSelectAllCheckbox: false,
            showTextRowsSelected: false,
          }}
          localization={{ ...DefaultLocalization }}
        />
      </Box>
    </OffCanvas>
  );
};
