import React, { useEffect, useState } from "react";
import {
  Outlet,
} from "react-router-dom";

import {
  getUser,
  notificationHook,
  MainTemplate,
  NotificationService,
} from 'itaf';

import NewPasswordCanvas from './NewPasswordCanvas';
import NotificationCanvas from './NotificationCanvas';
import Options from './Options';

function AppPage(props) {
	const [open, setOpen] = useState(false);
	const [notificationOpen, setNotificationOpen] = useState(false);
  const profileMenu = [
		{
			label: 'Alterar senha',
			action: function() { setOpen(true) },
		}
	];
  const [user, setUser] = useState(getUser());
  const [notesCount, setNotesCount] = notificationHook();

  useEffect(() => {
    if (user) {
      fetchNotesCount();
    }
	}, [user]);

  useEffect(() => {
  }, [notesCount]);

  function fetchNotesCount() {
    NotificationService.countUnreadNotes(user.id)
    .then((resp) => resp.text())
    .then((data) => {
      setNotesCount(data);
      // localStorage.setItem("notes_count", `${data}`);
    });
  }

  function openNotification() {
    setNotificationOpen(true);
  }

  return (
    <div sx={{
      display: "flex",
      maxWidth: "100vw",
      overflowX: "hidden"
    }}>
      <MainTemplate app="" menu={Options} profileMenu={profileMenu} 
        openNotification={openNotification} notesCount={notesCount}
      >
        <div sx={{
          flexGrow: 1,
          width: `calc(100vw - 240px)`,
          maxHeight: "100vh",
        }}>
          <Outlet />
        </div>
        <NewPasswordCanvas
          open={open}
          onCanvasClose={(oper) => setOpen(oper)}
          user={user}
        />
        <NotificationCanvas
          open={notificationOpen}
          onCanvasClose={(oper) => { setNotificationOpen(oper); fetchNotesCount(); }}
          user={user}
        />
      </MainTemplate>
    </div>
  );
}

export default AppPage;
