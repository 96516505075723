import { CustomerEndpoints } from '../types/Endpoints';

import { RequestService } from 'itaf';

const extraHeaders = {
  'x-app': 'customer'
};

class CustomerService {
  getCustomers() {
    const API_URL = CustomerEndpoints.CUSTOMER;
    return RequestService.getRequest(`${API_URL}`, {}, extraHeaders);
  }

  postCustomer(customer) {
    const API_URL = CustomerEndpoints.CUSTOMER;
    return RequestService.postRequest(`${API_URL}`, customer, {}, extraHeaders);
  }

  getCustomersByUserId(id) {
    const API_URL = CustomerEndpoints.CUSTOMERUSER;
    return RequestService.getRequest(`${API_URL}/user/${id}`, {}, extraHeaders);
  }

  postCustomerUser(customerUser) {
    const API_URL = CustomerEndpoints.CUSTOMERUSER;
    return RequestService.postRequest(`${API_URL}`, customerUser, {}, extraHeaders);
  }
}

export default new CustomerService();
