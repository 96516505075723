import { useEffect, useState } from "react";

import { NOTES } from '../types/Constants';

const decode = (value) => {
  return JSON.stringify(value)
}

const encode = (value) => {
  return JSON.parse(value === 'undefined' ? null : value)
}

const useLocalStorage = (key, defaultState) => {
  const [value, setValue] = useState(
    encode(localStorage.getItem(key)||null) || defaultState
  )

  useEffect(() => {
    localStorage.setItem(key, decode(value))
  },  [value])

  return [value, setValue]
}

const notificationHook = () => {
  const [value, setValue] = useState(
    encode(localStorage.getItem(NOTES) || null) || 0
  )

  useEffect(() => {
    localStorage.setItem(NOTES, decode(value))
  },  [value])

  return [value, setValue]
}

export { notificationHook, useLocalStorage };
