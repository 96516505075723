import React from 'react';
import { ResponsiveLine } from '@nivo/line';

import {
  Box,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';

const month = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul","Ago","Set","Out","Nov","Dez"];

export default function LineWidget({ data, title}) {

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Box height={272}>
          <ResponsiveLine
            data={data}
            // margin={{ left: 48, right: 40 }}
            margin={{ top: 0, right: 32, bottom: 68, left: 68 }}
            animate={true}
            enableSlices='x'
            colors={{ scheme: 'paired' }}
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
              useUTC: false,
              precision: 'month',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              // stacked: true,
              // reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              format: function(value) {
                const date = new Date(value);
                return month[(date.getMonth())] + " " + value.toLocaleDateString('pt-BR', { year : '2-digit'});
              },
              // format: '%b %y',
              tickSize: 10,
              tickPadding: 5,
              // tickRotation: 0,
              legend: 'Meses',
              legendOffset: 42,
              legendPosition: 'middle',
            }}
            axisLeft={null}
            // axisLeft={{
            //   orient: 'left',
            //   tickSize: 10,
            //   tickPadding: 5,
            //   // tickRotation: 0,
            //   legend: 'Valores em %',
            //   legendOffset: -42,
            //   legendPosition: 'middle',
            // }}
            enablePointLabel={true}
            // pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            
            legends={[]}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
