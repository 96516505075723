import { AccountEndpoints } from '../types/Endpoints';

import { RequestService } from 'itaf';

class UserService {
  registerUser(user) {
    const requestUrl = AccountEndpoints.Register;
    return RequestService.postRequest(requestUrl, user);
  }

  changePassaword(passwordObj) {
    const requestUrl = AccountEndpoints.PasswordChange;
    return RequestService.postRequest(requestUrl, { ...passwordObj });
  }

  fetchRegisteredUsers() {
    const requestUrl = AccountEndpoints.FetchUsers;
    return RequestService.getRequest(requestUrl);
  }

  fetchProducts() {
    const requestUrl = AccountEndpoints.FetchProducts;
    return RequestService.getRequest(requestUrl);
  }

  fetchProductsByUserId(userId) {
    const requestUrl = AccountEndpoints.FetchUsers;
    return RequestService.getRequest(`${requestUrl}/${userId}/products`);
  }
  
}

export default new UserService();
