export const bodyLocalization = {
	addTooltip: 'Adiciona linha',
	deleteTooltip: 'Remove linha',
	editTooltip: 'Edita linha',
	emptyDataSourceMessage: 'Nenhum registro cadastrado',
	editRow: {
		deleteText: 'Confirma deleção?',
		cancelTooltip: 'Cancelar',
		saveTooltip: 'Salvar',
	},
};

export const headerLocalization = {
	actions: '',
};

export const paginationLocalization = {
	labelRowsPerPage: 'Linhas por página',
	labelRowsSelect: 'linhas',
	labelDisplayedRows: ' {from}-{to} de {count}',
	firstTooltip: 'Primeira página',
	previousTooltip: 'Página anterior',
	nextTooltip: 'Próxima página',
	lastTooltip: 'Última página',
};

export const toolbarLocalization = {
	addRemoveColumns: 'Adiciona ou remove colunas',
	exportAriaLabel: 'Exportar',
	exportName: 'Exporta para CSV',
	exportTitle: 'Exportar',
	showColumnsAriaLabel: 'Exibe colunas',
	showColumnsTitle: 'Exibe colunas',
	searchPlaceholder: 'Pesquisar',
	searchTooltip: 'Pesquisar',
};

const DefaultLocalization = {
	body: { ...bodyLocalization },
	header: { ...headerLocalization },
	pagination: { ...paginationLocalization },
	toolbar: { ...toolbarLocalization },
};

export default DefaultLocalization;