import React, { useRef, useState } from 'react';
import {
	Grid,
	IconButton,
	InputAdornment,
	TextField,
} from '@mui/material';
import {
	Alert,
	LoadingButton,
} from '@mui/lab';
import {
	AccountCircle,
	LoginRounded,
	Visibility,
	VisibilityOff,
	VpnKeyRounded,
} from '@mui/icons-material';

// const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const isEmailValid = (email) => emailRegex.test(email);

const usernameRegex = /^[A-Za-z0-9|_|-]+$/;
const isUsernameValid = (username) => usernameRegex.test(username);

const isPasswordValid = (password) => Boolean(password);

const LoginContainer = (props) => {
	const buttonRef = useRef();

	const [state, setState] = useState({ username: '', password: '' });
	const [visible, setVisible] = useState(false);

	const handleClickShowCurrentPassword = () => {
    setVisible(!visible);
  };

	const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

	const handleOnEmailChange = (event) => {
		setState({ ...state, username: event.target.value });
	}

	const handleOnPasswordChange = (event) => {
		setState({ ...state, password: event.target.value });
	}

	const handleOnClick = () => {
		props.onLoginClick(state.username, state.password);
	};

	const isFormValid = () => {
		return isUsernameValid(state.username) && isPasswordValid(state.password);
	};

	const handleKeyPress = (e) => {
    if(e.key === "Enter"){
      handleOnClick();
    }
  };

	return (
		<Grid container spacing={2}>
			{props.message?.hasError && <Alert severity="error">{props.message?.messageError}</Alert>}
			<Grid item xs={12}>
				<TextField
					id="username"
					label="Usuário"
					type="email"
					value={state.username}
					onChange={handleOnEmailChange}
					fullWidth
					required
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<AccountCircle />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					id="password"
					label="Senha"
					type={visible ? 'text' : 'password'}
					onChange={handleOnPasswordChange}
					onKeyPress={handleKeyPress}
					fullWidth
					required
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<VpnKeyRounded />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowCurrentPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{visible ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
			</Grid>
			<Grid item xs={12}>
				<LoadingButton
				  ref={buttonRef}
					color="primary"
					loading={props.isLoading}
					loadingPosition="start"
					startIcon={<LoginRounded />}
					variant="contained"
					onClick={handleOnClick}
					disabled={!isFormValid()}
					fullWidth
				>
					{props.isLoading ? 'Validando usuário...' : 'Entrar'}
				</LoadingButton>
			</Grid>
		</Grid>
	);
}

export default LoginContainer;
