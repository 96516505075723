import React from 'react';
import ReactDOM from 'react-dom';
import LocalRoutes from './routes';
// import history from './browserHistory';
import ItafTheme from './themes';

import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material';
// import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
// import { CssBaseline } from "@mui/material";

import CommonProvider from './context/ProviderContext';

ReactDOM.render(
  (<CommonProvider>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={ItafTheme.default}>
        <CssBaseline />
        <LocalRoutes />
      </ThemeProvider>
    </StyledEngineProvider>
  </CommonProvider>
  ), document.getElementById('root')
);
