const ACCOUNT_API_BASE_URL = process.env.REACT_APP_ACCOUNT_API_URL || '/itafaccount';
const CADASTRO_API_BASE_URL = process.env.REACT_APP_CADASTRO_API_URL || '/itaf';
const CUSTOMER_API_BASE_URL = process.env.REACT_APP_CUSTOMER_API_URL || '/itaf';
const NOTIFICATION_API_BASE_URL = process.env.REACT_APP_NOTIFICATION_API_URL || '/itaf';
const SPED_API_BASE_URL = process.env.REACT_APP_SPED_API_URL || '/itaf';

export const AccountEndpoints = {
  Authenticate: `${ACCOUNT_API_BASE_URL}/auth`,
  PasswordChange: `${ACCOUNT_API_BASE_URL}/users/password/change`,
  Register: `${ACCOUNT_API_BASE_URL}/users/signup`,
  FetchRoles: `${ACCOUNT_API_BASE_URL}/roles`,
  FetchProducts: `${ACCOUNT_API_BASE_URL}/products`,
  FetchUsers: `${ACCOUNT_API_BASE_URL}/users`,
  FetchCustomers: `${CUSTOMER_API_BASE_URL}/users`,
};

export const CadastroEndpoints = {
  Dashboard: `${CADASTRO_API_BASE_URL}/graphics/situacao`,
};

export const CustomerEndpoints = {
	CUSTOMER_API: `${CUSTOMER_API_BASE_URL}`,
	CUSTOMER: `${CUSTOMER_API_BASE_URL}/customers`,
	CUSTOMERUSER: `${CUSTOMER_API_BASE_URL}/customeruser`,
}

export const NotificationEndpoints = {
	NOTIFICATION_API: `${NOTIFICATION_API_BASE_URL}`,
	NOTIFICATION: `${NOTIFICATION_API_BASE_URL}/notifications`,
};

export const SpedEndpoints = {
  Dashboard: `${SPED_API_BASE_URL}/estatistica/dashboard`,
  Selic: `${SPED_API_BASE_URL}/selic/graphic`,
};
