import React, { useEffect, useRef, useState } from 'react';
import TableIconButton from '../TableIconButton';
import {DeleteIcon, EditIcon, InsertIcon, ChartIcon} from '../../icons';

import {
  Box,
  IconButton, 
  Tooltip,
  Typography,
} from '@mui/material';

import MaterialReactTable, {
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';

import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

export default function Table(props) {
  const [isLoading, setLoading] =  useState(false);
  const [showProgressBars, setShowProgressBars] =  useState(false);
  const [expanded, setRowExpanded] = useState({});
  const [options, setOptions] = useState({});
  const [containerHeight, setContainerHeight] = useState(224);
  const tableInstanceRef = useRef(null);

  const [ columnFilters, setColumnFilters ] = useState([],);
  const [ globalFilter, setGlobalFilter ] = useState('');
  const [ sorting, setSorting ] = useState([]);
  const [ pagination, setPagination ] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  
  useEffect(() => {
    let opt = { ...options }
    if (props.pagination) {
      opt = { ...opt,
        enableBottomToolbar: true,
        enablePagination: true,
        paginateExpandedRows: false,
        positionPagination: "both",
      }
      // setContainerHeight(containerHeight + 82);
    }
    if (props.manualPagination) {
      opt = { ...opt,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiTablePaginationProps: { ...{
          showFirstButton: false,
          showLastButton: false,
        }},
      }
      // setState({
      //   columnFilters,
      //   globalFilter,
      //   pagination,
      //   showProgressBars,
      //   sorting,
      // })
    }
    if (props.rowActions) {
      opt = { ...opt,
        displayColumnDefOptions: { ...props.rowActions },
      };
    }
    // Para usar a própria edição da tabela
    if (props.rowEditing) {
      // const width = !!props.widthActionCell ? props.widthActionCell : 25;
      opt = { ...opt,
        editingMode: "row", //default model
        enableEditing: true,
        enableRowActions: true,
        displayColumnDefOptions: {
          'mrt-row-actions': {
            header: '',
            maxSize: 25,
            size: 25,
          }
        },
      };
    }
    if (props.cellEditing) {
      // const width = !!props.widthActionCell ? props.widthActionCell : 25;
      opt = { ...opt,
        editingMode: "cell", //default model
        enableEditing: true,
      };
    }
    if (props.widthActionCell) {
      opt = { ...opt,
        displayColumnDefOptions: {
          'mrt-row-actions': {
            header: '',
            ...props.widthActionCell,
          }
        },
      };
    }
    if (props.enableExpanding || props.enableRowSelection) {
      opt = { ...opt,
        displayColumnDefOptions: {
          ...opt.displayColumnDefOptions, // adicionar o mrt-row-actions se for o caso
          'mrt-row-expand': {
            maxSize: 15,
            minSize: 15,
            size: 15,
          },
          'mrt-row-select': {
            maxSize: 15,
            minSize: 15,
            size: 15,
          },
        }
      };
    }
    if (props.details) {
      opt = { ...opt,
        displayColumnDefOptions: {
          ...opt.displayColumnDefOptions,
          'mrt-row-expand': {
            maxSize: 15,
            minSize: 15,
            size: 15,
          },
        }
      };
    }
    
    setOptions({ ...opt });
  }, []);
    
  useEffect(() => {
    setLoading(props.isLoading);
  }, [props.isLoading]);

  useEffect(() => {
    setShowProgressBars(props.isRefetching);
  }, [props.isRefetching]);

  useEffect(() => {
    if (props.onExpandedChange) {
      props.onExpandedChange(expanded);
    }
  }, [expanded]);

  useEffect(() => {
    if (props.setTableInstanceRef) {
      props.setTableInstanceRef(tableInstanceRef);
    }
  }, [tableInstanceRef]);

  useEffect(() => {
    if (props.fetchData) {
      props.fetchData(pagination, globalFilter, sorting, columnFilters);
    }
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  function getEditButton(row) {
    return (
      <TableIconButton tooltip="Editar" 
        onClick={(event) => {
          event.stopPropagation();
          props.onEdit(row);
        }}
      >
        <EditIcon/>
      </TableIconButton>);
  }

  function getDeleteButton(row) {
    return (
      <TableIconButton tooltip="Remover" 
        onClick={(event) => {
          event.stopPropagation();
          props.onDelete(row);
        }}
      >
        <DeleteIcon color="error" />
      </TableIconButton>);
  }

  const handleSaveCell = (cell, value) => {
    if (props.onEditingRowSave) {
      if (!props.onEditingRowSave(cell, value)) {
        return;
      }
    }
  };

  const handleSaveRowEdits =
    async ({ exitEditingMode, row, values }) => {
      if (props.onEditingRowSave) {
        if (!props.onEditingRowSave(row, values)) {
          return;
        }
      }

      exitEditingMode(); //required to exit editing mode and close modal
    };

  const handleCancelRowEdits = ({ row, table }) => {
    if (props.onEditingRowCancel) {
      props.onEditingRowCancel(row);
    }
  };

  return (
    <MaterialReactTable
      columns={props.columns}
      data={props.data}
      state={{ isLoading, expanded, 
        columnFilters,
        globalFilter,
        pagination,
        showProgressBars,
        sorting,
        ...props.state
      }}
      renderTopToolbarCustomActions={() => <Typography variant="h4">{props.title}</Typography>}
      layoutMode="grid"
      localization={{ ...MRT_Localization_PT_BR, actions: '' }}
      positionActionsColumn="last"
      enableStickyHeader
      muiTableContainerProps={{ sx: { maxHeight: `calc(100vh - ${containerHeight}px)` } }}
      muiTableHeadCellProps={{
        sx: (theme) => ({
          borderRight: '1px solid rgba(224,224,224,1)',
        })
      }}
      muiTableBodyProps={({ table }) => {
        if (table?.getAllColumns()[0]?.id === 'mrt-row-expand') {
          return {sx: (theme) => ({
            '& tr:nth-child(4n+3)': {
              backgroundColor: "#F6F7FF"
            },
          })}
        } else {
          return {sx: (theme) => ({
            '& tr:nth-of-type(odd)': {
              backgroundColor: "#F6F7FF"
            },
          })}
        }
      }}
      // muiTableBodyRowProps={props.clickRowExpand}
      muiTableBodyRowProps={({ isDetailPanel, row, table }) => {
        if (isDetailPanel) {
          return {sx: (theme) => ({
              height: 'auto',
            })}
        } else {
          if (props?.clickRowExpand) {
            return {
              onClick: (event) => props.clickRowExpand(event, row),
              sx: (theme) => ({
              cursor: 'pointer'
            })}
            // props?.clickRowExpand({ isDetailPanel, row, table });
          }
        }
      }}
      muiTableBodyCellProps={{
        sx: {
          borderRight: '1px solid #e0e0e0', //add a border between columns
        },
      }}
      // muiTableBodyCellEditTextFieldProps={({ cell }) => ({
      //   //onBlur is more efficient, but could use onChange instead
      //   onBlur: (event) => {
      //     handleSaveCell(cell, event.target.value);
      //   },
      // })}
      enableBottomToolbar={props.enableBottomToolbar !== false && true}
      enableEditing={props.enableEditing}
      enableExpandAll={props.enableExpandAll}
      enableExpanding={props.enableExpanding}
      enableColumnOrdering={props.enableColumnOrdering}
      enableColumnFilters={props.enableColumnFilters !== false && true}
      enableColumnResizing={props.enableColumnResizing || false}
      enableGlobalFilterModes={true}
      enableHiding={props.enableHiding !== false && true}
      enablePagination={props.enablePagination !== false && true}
      enablePinning={props.enablePinning !== false && true}
      enableRowActions={props.enableRowActions}
      enableRowSelection={props.enableRowSelection}
      enableSorting={props.enableSorting !== false && true}
      positionToolbarAlertBanner={false}
      initialState={{
        showGlobalFilter: true,
        expanded: false,
        ...props.initialState,
      }}
      renderDetailPanel={props.details}
      onEditingRowCancel={handleCancelRowEdits}
      onEditingRowSave={handleSaveRowEdits}
      onExpandedChange={setRowExpanded}
      renderToolbarInternalActions={!props.buttonInsertTitle && !props.onChart ? null :
        ({ table }) => (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <MRT_ToggleFiltersButton table={table} />
          {(props.enableHiding !== false && true) && <MRT_ShowHideColumnsButton table={table} />}
          <MRT_ToggleDensePaddingButton table={table} />
          <MRT_FullScreenToggleButton table={table} />
          {props.onInsert && <Tooltip arrow title={props.buttonInsertTitle || ''}>
            <IconButton onClick={(event) => props.onInsert(props.record)}>
              <InsertIcon />
            </IconButton>
          </Tooltip>}
          {props.onChart && <Tooltip arrow title='Exibe gráfico'>
            <IconButton onClick={(event) => props.onChart(props.record)}>
              <ChartIcon />
            </IconButton>
          </Tooltip>}
        </Box>
      )}
      renderRowActions={!props.onDelete && !props.onEdit && !props.moreButtons 
        ? null : ({ row }) => (
        <div style={{
          display: 'flex',
          flexWrap: 'nowrap',
          gap: '0.5rem'
        }}>
          {props.onEdit && getEditButton(row)}
          {props.onDelete && getDeleteButton(row)}
          {props.moreButtons && props.moreButtons(row)}
        </div>
      )}
      tableInstanceRef={tableInstanceRef}
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      rowCount={props?.rowCount}
      { ...options } // Adiciona opcoes especificas de cada tela
      { ...props.moreProps }
      // renderRowActions={null}
      //{({ cell, row, table }) => (<MRT_EditActionButtons row={row} table={table} />)}
      
      
      // // columnResizeMode="onEnd"
      // // enableColumnResizing
      // // enableColumnVirtualization
      // renderToolbarInternalActions={({ table }) => {
        //   return <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      //     <MRT_ToggleFiltersButton table={table} />
      //     <MRT_ShowHideColumnsButton table={table} />
      //     <MRT_ToggleDensePaddingButton table={table} />
      //     <MRT_FullScreenToggleButton table={table} />
      //     <Tooltip arrow title="Novo relatório">
      //       <IconButton onClick={(event) => { props.openCanvas(); }}>
      //         <AddBoxIcon />
      //       </IconButton>
      //     </Tooltip>
      //   </Box>;
      // }}
      // displayColumnDefOptions={{
      //   'mrt-row-actions': {
      //     header: '',
      //     size: 25,
      //     minSize: 25,
      //     Cell: ({ row, table }) => (
      //       <Tooltip arrow placement="left" title="Edit">
      //         <IconButton onClick={() => props.openCanvas(row) }>
      //           <EditIcon/>
      //         </IconButton>
      //       </Tooltip>
      //     ),
      //   },
      // }}
      
      // detailPanel={rowData => {
      //   return (
      //     <div>teste</div>
      //     <UsersDetail data={rowData} users={props.users} selectUser={(rows, reportId) => selectUser(rowData, rows, reportId)}/>
      //   );
      // }}
      // options={{
      //   ...DefaultOptions,
      //   // actionsColumnIndex: 0,
      //   maxBodyHeight: "calc(100vh - 256px)",
      //   // detailPanelType: "single",
      //   detailPanelColumnAlignment: "right",
      //   grouping: false,
      //   paging: false,
      //   mode: "bulk",
      //   editable: true,
      // }}
      // localization={{ ...DefaultLocalization }}
    />
  );
};
