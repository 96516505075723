import { AccountEndpoints } from '../types/Endpoints';
import { 
  deleteRequest,
  getRequest,
  postRequest,
} from './RequestService';

async function login(username, password) {
  const requestUrl = (await AccountEndpoints).Login;
  const response = await postRequest(requestUrl, { username, password });

  const responseBody = await response.json()
  if (!response.ok) {
    throw responseBody;
  }

  return responseBody;
}

async function logout() {
  const requestUrl = AccountEndpoints.Logout;
  const response = await deleteRequest(requestUrl);

  if (!response.ok) {
    throw new Error();
  }

  return response.statusText;
}

async function authenticate() {
  const requestUrl = AccountEndpoints.Authenticate;
  const response = await getRequest(requestUrl);
  
  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }

  return responseBody;
}

const AuthService = {
  authenticate,
  login,
  logout,
};

export default AuthService;
