import React from "react";

import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DescriptionIcon from '@mui/icons-material/Description';
import ExploreIcon from "@mui/icons-material/Explore";
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import HomeIcon from "@mui/icons-material/Home";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const Options = [
	{
		productName: "DASHBOARD",
		label: "Dashboard",
		link: "dashboard",
		icon: <HomeIcon />,
	},
	{
		productName: "FISCAL",
		label: "Fiscal",
		link: "fiscal",
		icon: <ExploreIcon />,
	},
	{
		productName: "BUSINESS",
		label: "Cadastro",
		link: "cadastros",
		icon: <ListAltIcon />,
	},
	{
		productName: "CONTROLLERSHIP",
		label: "Controladoria",
		link: "controladoria",
		icon: <CorporateFareIcon />,
	},
	{
		productName: "REPORT",
		label: "Relatórios",
		link: "relatorios",
		icon: <DescriptionIcon />,
	},
	{
		productName: "CUSTOMER",
		label: "Cliente",
		link: "clientes",
		icon: <ContactsRoundedIcon />,
	},
	{
		productName: "SPED",
		label: "Fiscal",
		link: "sped",
		icon: <HeatPumpIcon />,
	},
	{
		productName: "USER",
		label: "Usuários",
		link: "usuarios",
		icon: <PeopleAltIcon />,
	},
];

export default Options;
