import * as React from 'react';

import {DeleteIcon} from '../../icons';

import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
 } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ConfirmationDialog(props) {
  const { onClose, open, title, data, description, isDelete, ...other } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <BootstrapDialog
      sx={{ '& .MuiDialog-paper': { width: '80%' } }}
      open={open}
      {...other}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {isDelete 
          ? (<Button startIcon={<DeleteIcon color="error" />} sx={{ cursor: 'unset', padding: 0 }}>
              {title}
            </Button>)
          : (title)
        }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography>{description}</Typography>
        <Typography>{data}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Não
        </Button>
        <Button onClick={handleOk}>Sim</Button>
      </DialogActions>
    </BootstrapDialog>
  );
}