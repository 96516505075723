const ACCOUNT_API_BASE_URL = process.env.REACT_APP_ACCOUNT_API_URL || '/itafaccount';
const NOTIFICATION_API_BASE_URL = process.env.REACT_APP_NOTIFICATION_API_URL || '/itaf';

export const AccountEndpoints = {
    Authenticate: `${ACCOUNT_API_BASE_URL}/auth`,
    Login: `${ACCOUNT_API_BASE_URL}/users/signin`,
    Logout: `${ACCOUNT_API_BASE_URL}/users/signout`,
}

export const NotificationEndpoints = {
	NOTIFICATION_API: `${NOTIFICATION_API_BASE_URL}`,
	NOTIFICATION: `${NOTIFICATION_API_BASE_URL}/notifications`,
};
