import React from 'react';
import { IMaskInput } from 'react-imask';

const CpfMaskedInput = React.forwardRef(function CpfMaskedInput(props, ref) {
	const { onChange, ...other } = props;
  return (
		<IMaskInput
			{...other}
			inputRef={ref}
			// mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/,'.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
			mask="000.000.000-00"
			onAccept={(value) => onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});

export default CpfMaskedInput;

// mask="(#00) 000-0000"
// definitions={{
// 	'#': /[1-9]/,
// }}
// inputRef={ref}
