import { SpedEndpoints } from '../types/Endpoints';

import { RequestService } from 'itaf';

const extraHeaders = {
  'x-app': 'sped'
};

class SpedService {
  fetchSelic() {
    const API_URL = SpedEndpoints.Selic;
    return RequestService.getRequest(`${API_URL}`, {}, extraHeaders);
  }

  fetchDashboardSpedData() {
    const API_URL = SpedEndpoints.Dashboard;
    return RequestService.getRequest(`${API_URL}`, {}, extraHeaders);
  }
}

export default new SpedService();
