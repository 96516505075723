import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppRoutes } from '../routes';

import { 
	AuthService,
} from 'itaf';

const ProtectedRoute = ({ children }) => {

  const [state, setState] = useState({
    isFetching: true,
    isAuthenticated: false,
  });

  useEffect(() => {
    AuthService.authenticate()
      .then((user) => {
        setState({ isFetching: false, isAuthenticated: true });
      })
      .catch((e) => {
				console.log(e)
        setState({ isFetching: false, isAuthenticated: false });
      });
  }, [children]);

  if (state.isFetching) {
    return null;
  }

  if (!state.isAuthenticated) {
    return <Navigate to={AppRoutes.LOGIN} replace />
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
