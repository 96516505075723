import React, { useEffect, useState } from 'react';

import { Grid, Link } from '@mui/material';

import { getUser } from 'itaf';

import LineWidget from '../../components/LineChartWidget';
import SunburstChartWidget from '../../components/SunburstChartWidget';
import Widget from "../../components/Widget";

import CadastroService from '../../services/CadastroService';
import SpedService from '../../services/SpedService';

const DashboardContainer = () => {
  const [dashboardCnpj, setDashboardCnpj] = useState({children: []});
  const [dashboardSped, setDashboardSped] = useState([]);
  const [dashboardSelic, setDashboardSelic] = useState([]);
  
  useEffect(() => {
    const items = [];
    const user = getUser();
    const userProducts = user.products.find((item) => item.name === 'DASHBOARD');
    if (userProducts) {
      userProducts?.items.forEach((product) => {
        if (product.name === 'DASH_GR_SELIC') {
          loadDashboardSelic();
        }
        else if (product.name === 'DASH_GR_EMPR') {
          loadDashboardEmpresas();
        }
      });
    }
  }, []);
  
  function loadDashboardEmpresas() {
    CadastroService.fetchDashboardCnpjData()
      .then(async (res) => {
        if (res.ok) {
          const body = await res.json();
          setDashboardCnpj(body);
        }
      });
  }

  function loadDashboardSpedData() {
    SpedService.fetchDashboardSpedData()
      .then(async (res) => {
        if (res.ok) {
          const body = await res.json();
          setDashboardSped(body);
        }
      });
  }

  function loadDashboardSelic() {
    SpedService.fetchSelic()
      .then(async (res) => {
        if (res.ok) {
          const body = await res.json();
          const data = [];
          body.map((item) => {
            data.push({ x: `${item.dtfim}`, y: item.selic });
          });
          const dat = [{ data: [ ...data ], id: "selic", }];
          setDashboardSelic(dat);
        }
      });
  }

  return (
    <Grid container spacing={4}>
      {dashboardSelic?.length && 
        <Grid item sm={6} xs={12}>
          <LineWidget title="Taxa Selic dos últimos 12 meses" data={dashboardSelic} />
        </Grid>
      }
      {dashboardCnpj.children?.length && 
        <Grid item sm={6} xs={12}>
          <SunburstChartWidget title="CNPJ - Cadastro Nacional de Pessoa Jurídica" data={dashboardCnpj} />
        </Grid>
      }
      {/* <Grid item lg={2} md={4} sm={6} xs={12}>
        <Link href="/app/sped" underline="none" key="sped">
          <Widget title="Auditoria" data={dashboardSped} />
        </Link>
      </Grid> */}
    </Grid>
  );
};

export default DashboardContainer;
